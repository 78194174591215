import { ReactNode, useCallback, useEffect, useState } from 'react';
import { InputMask, InputMaskCompleteEvent } from 'primereact/inputmask';

import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';

import styles from './TimeRange.module.scss';

const TIME_REGEX = /^([0-1_]?[0-9_]|2[0-3_]):[0-5_][0-9_]$/;

type TimeRangeProps = {
  time?: Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>> | null;
  onChange?: (
    time: Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>>
  ) => void;
  onTimeToBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

const TimeRange = ({
  time,
  onChange,
  onTimeToBlur,
}: TimeRangeProps): ReactNode => {
  const [timeFrom, setTimeFrom] = useState<string | null | undefined>(
    time?.from?.toFormat('HH:mm')
  );
  const [timeTo, setTimeTo] = useState<string | null | undefined>(
    time?.to?.toFormat('HH:mm')
  );

  const validate = (newValue: string | null | undefined): boolean =>
    newValue ? TIME_REGEX.test(newValue) : true;

  useEffect(() => {
    if (time) {
      setTimeFrom(time.from?.toFormat('HH:mm'));
      setTimeTo(time.to?.toFormat('HH:mm'));
    }
  }, [time]);

  const onFromTimeComplete = useCallback(
    ({ value }: InputMaskCompleteEvent) => {
      if (
        value &&
        validate(value) &&
        value !== time?.from?.toFormat('HH:mm')
      ) {
        const [hour = '0', minute = '0'] = value?.split(':') || [];
        const timeToDateTime = time?.to ? time?.to : time?.from;

        onChange &&
          onChange({
            from: value
              ? time?.from?.set({ hour: +hour, minute: +minute })
              : undefined,
            to: timeToDateTime,
          });
      } else {
        setTimeFrom(null);
      }
    },
    [time, onChange]
  );
  const onToTimeComplete = useCallback(
    ({ value }: InputMaskCompleteEvent) => {
      if (
        value &&
        validate(value) &&
        value !== time?.to?.toFormat('HH:mm')
      ) {
        const [hour = '0', minute = '0'] = value?.split(':') || [];
        const timeToDateTime = time?.to ? time?.to : time?.from;

        onChange &&
          onChange({
            from: time?.from,
            to: value
              ? timeToDateTime?.set({ hour: +hour, minute: +minute })
              : undefined,
          });
      } else {
        setTimeTo(null);
      }
    },
    [time, onChange]
  );

  const onTimeChanged = useCallback(() => {
    if (
      timeFrom !== time?.from?.toFormat('HH:mm') ||
      timeTo !== time?.to?.toFormat('HH:mm')
    ) {
      const [hourFrom = '0', minuteFrom = '0'] = timeFrom?.split(':') || [];
      const [hourTo = '0', minuteTo = '0'] = timeTo?.split(':') || [];
      const timeToDateTime = time?.to ? time?.to : time?.from;

      setTimeFrom(timeFrom);
      setTimeTo(timeTo);

      onChange &&
        onChange({
          from: timeFrom
            ? time?.from?.set({ hour: +hourFrom, minute: +minuteFrom })
            : undefined,
          to: timeTo
            ? timeToDateTime?.set({ hour: +hourTo, minute: +minuteTo })
            : undefined,
        });
    }
  }, [time, timeFrom, timeTo, onChange]);

  return (
    <div className={styles.dateTimeRangeSelector}>
      <div>
        <label htmlFor='fromTime'>From</label>
        <span />
        <label htmlFor='toTime'>To</label>
      </div>
      <div>
        <InputMask
          id='fromTime'
          value={timeFrom}
          mask='99:99'
          placeholder='hh:mm'
          slotChar='00:00'
          autoClear
          onBlur={() => {
            if (validate(timeFrom)) {
              onTimeChanged();
            } else {
              setTimeFrom(null);
            }
          }}
          onComplete={onFromTimeComplete}
          onChange={e => {
            setTimeFrom(e.value || '00:00');
          }}
        />
        <span>-</span>
        <InputMask
          id='toTime'
          value={timeTo}
          mask='99:99'
          placeholder='hh:mm'
          slotChar='23:59'
          autoClear
          onBlur={e => {
            if (validate(timeTo)) {
              onTimeChanged();
            } else {
              setTimeTo(null);
            }
            onTimeToBlur && onTimeToBlur(e);
          }}
          onComplete={onToTimeComplete}
          onChange={e => {
            setTimeTo(e.value || '00:00');
          }}
        />
      </div>
    </div>
  );
};

export default TimeRange;
export { TimeRange };
