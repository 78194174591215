import {
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Calendar, CalendarSelectEvent } from 'primereact/calendar';
import { Menu } from 'primereact/menu';

import { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';

import TimeRange from './Components/TimeRange';

import './DateTimePicker.scss';

type DateTimePickerRef = {
  hide?: (e: SyntheticEvent) => void;
  show?: (e: SyntheticEvent) => void;
};
type DateTimePickerProps = {
  value?: Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>> | null;
  calendarClassName?: string;
  onValueChange?: (
    value:
      | Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>>
      | null
      | undefined
  ) => void;
};

const DateTimeComponent = (
  {
    value,
    calendarClassName,
    onValueChange = (): void => {},
  }: DateTimePickerProps,
  ref: ForwardedRef<DateTimePickerRef>
): ReactNode => {
  const [date, setDate] = useState<
    Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>> | null | undefined
  >({
    ...value,
    to:
      value?.to?.set({ second: 0 }).toMillis() === value?.from?.toMillis()
        ? undefined
        : value?.to,
  });

  const menuRef = useRef<Menu>(null);

  useImperativeHandle(ref, () => ({
    hide: menuRef.current?.hide,
    show: menuRef.current?.show,
  }));

  useEffect(() => {
    /**
     * 'to' date time +59sec will be received
     * in case of exact day sent to date time parser API
     * so consider it as there is not 'to' date
     */
    const to =
      value?.to?.set({ second: 0 }).toMillis() === value?.from?.toMillis()
        ? undefined
        : value?.to;
    setDate({
      ...value,
      to,
    });
  }, [value]);

  const updateDate = useCallback(
    (
      dateTime:
        | Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>>
        | null
        | undefined
    ) => {
      setDate(dateTime);
      onValueChange(dateTime);
    },
    [onValueChange]
  );

  const onDateChange = useCallback(
    (e: CalendarSelectEvent): void => {
      const [from, to] = e.value as Date[];
      const dateTime = {
        from:
          from &&
          DateTime.fromJSDate(from).startOf('day'),
        to:
          to && DateTime.fromJSDate(to).endOf('day'),
      };
      updateDate(dateTime);
    },
    [updateDate]
  );

  const onTimeChange = useCallback(
    (value: Partial<Pick<ParssedDateTimeResult, 'from' | 'to'>>) => {
      const isSwapped = value.from && value.to && value.from > value.to;
      const dateTime = isSwapped ? { from: value.to, to: value.from } : value;
      updateDate(dateTime);
    },
    [updateDate]
  );

  const preventMenuClose = (e: SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    /**
     * prevent menu close on `Tab` press
     */
    if (e.code === 'Tab') {
      menuRef.current?.show(e);
    }
  };

  return (
    <Menu
      popup
      ref={menuRef}
      className='datetime-picker-menu'
      onKeyDown={onMenuKeyDown}
      model={[
        {
          template: (
            <Calendar
              className={clsx('datetime-picker-calendar', calendarClassName)}
              inline
              hourFormat='24'
              selectionMode='range'
              value={
                date?.from && [
                  date.from.startOf('day').toJSDate(),
                  date?.to?.startOf('day').toJSDate() || null,
                ]
              }
              hideOnRangeSelection
              onChange={onDateChange as any}
              footerTemplate={() => (
                <TimeRange
                  time={date}
                  onChange={onTimeChange}
                  onTimeToBlur={menuRef.current?.hide}
                />
              )}
              pt={{
                root: {
                  onClick: preventMenuClose,
                },
              }}
            />
          ),
        },
      ]}
    />
  );
};

const DateTimePicker = forwardRef<DateTimePickerRef, DateTimePickerProps>(
  DateTimeComponent
);

export default DateTimePicker;
export { DateTimePicker, type DateTimePickerRef };
